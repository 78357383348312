import React, { useRef } from 'react'
import { MenuPage } from './pages/MenuPage'
import { ContectBox } from './components/ContectBox'
import { Routes, Route } from 'react-router-dom'
import { CVMakerPage } from './pages/CVMakerPage'
import { AboutPage } from './pages/AboutPage'

function App() {
  const contentStartRef = useRef<null | HTMLDivElement>(null)

  return (
    <>
      {/* <ContectBox> */}
        <Routes >
          <Route path='/' element={<MenuPage />} />
          <Route path='/cv' element={<CVMakerPage />} />
          <Route path='/about' element={<AboutPage />} />
        </Routes>
      {/* </ContectBox> */}
    </>
  )
}

export default App;
